<template>
	<n-card style="width: 100%; height: 80vh;">
		<n-grid cols="xs:10 m:7 l:7 xl:5 2xl:5" responsive="screen">
			<n-gi :span="showSpecs ? 'xs:5 m:3 l:3 xl:1 2xl:1' : '0'">
				<n-grid :cols=10>
					<n-gi :span=9>
						<n-scrollbar style="max-height: 75vh; padding-right: .7vw;">
							<n-space vertical item-style="user-select: none;">										
								<n-grid :cols=25>
									<n-gi :span=12>
										<n-input placeholder=" " v-model:value="userId" v-on:keyup.enter="search">
											<template #prefix>ID</template>
										</n-input>
									</n-gi>
									<n-gi :offset=1 :span=12>
										<n-input placeholder=" " v-model:value="paramsData['target']" v-on:keyup.enter="search">
											<template #prefix>TARGET</template>
										</n-input>
									</n-gi>
								</n-grid>

								<n-input v-for="param in params" placeholder=" " v-model:value="paramsData[param]" v-on:keyup.enter="search">
									<template #prefix>{{ param }}</template>
								</n-input>

								<n-date-picker v-model:value="dateRange" type="daterange" />

								<n-grid :cols=5>
									<n-gi :span=4>
										<n-button size="tiny" block type="primary" @click="search" :loading="loading">
											Search
										</n-button>
									</n-gi>
									<n-gi>
										<n-button size="tiny" block type="secondary" @click="reset" :disabled="loading">
											Reset
										</n-button>
									</n-gi>
								</n-grid>

								
									<!-- checkbox cu descriere -->
								<!-- 
									<n-popover trigger="hover" placement="right">
										<template #trigger><n-checkbox value="Death" :label="dictionary['Death'][0]" /></template>
										<span><i class="fa-regular fa-circle-info"></i> {{ dictionary['DamageDealt'][1] }}</span>
									</n-popover> 
								-->

								<n-checkbox-group v-model:value="types">
									<n-space vertical>
										
										<n-divider title-placement="left" style="margin-top: 20px; margin-bottom: 0;">
											Generale
										</n-divider>
										<n-checkbox value="GiveMoney" :label="dictionary['GiveMoney']" />
										<n-checkbox value="BankTransfer" :label="dictionary['BankTransfer']" />
										<n-checkbox value="FireCoinsPayment" :label="dictionary['FireCoinsPayment']" />
										<n-checkbox value="FireCoinsRecive" :label="dictionary['FireCoinsRecive']" />
										<n-checkbox value="BuyVeh" :label="dictionary['BuyVeh']" />
										<n-checkbox value="SellVeh" :label="dictionary['SellVeh']" />
										<n-checkbox value="RamVeh" :label="dictionary['RamVeh']" />
										<n-checkbox value="Login" :label="dictionary['Login']" />
										<n-checkbox value="Logout" :label="dictionary['Logout']" />
										<n-checkbox value="Death" :label="dictionary['Death']" />
										<n-checkbox value="DamageDealt" :label="dictionary['DamageDealt']" />
										<n-checkbox value="RecivedCPR" :label="dictionary['RecivedCPR']" />
										<n-checkbox value="Died" :label="dictionary['Died']" />
										<n-checkbox value="winBarbut" :label="dictionary['winBarbut']" />
										<n-checkbox value="investments" :label="dictionary['investments']" />
										<n-checkbox value="achiv" :label="dictionary['achiv']" />
										<n-checkbox value="confiscari" :label="dictionary['confiscari']" />

										<n-divider title-placement="left" style="margin-top: 5px; margin-bottom: 0;">
											Inventar
										</n-divider>
										<n-checkbox value="InventoryDrop" :label="dictionary['InventoryDrop']" />
										<n-checkbox value="InventoryPickUp" :label="dictionary['InventoryPickUp']" />
										<n-checkbox value="InventoryFromStash" :label="dictionary['InventoryFromStash']" />
										<n-checkbox value="InventoryToStash" :label="dictionary['InventoryToStash']" />
										<n-checkbox value="InventoryToTarget" :label="dictionary['InventoryToTarget']" />
										<n-checkbox value="CraftItem" :label="dictionary['CraftItem']" />

										<n-divider title-placement="left" style="margin-top: 5px; margin-bottom: 0;">
											Mafia / Heist
										</n-divider>
										<n-checkbox value="VangelicoHeist" :label="dictionary['VangelicoHeist']" />
										<n-checkbox value="PacificHeist" :label="dictionary['PacificHeist']" />
										<n-checkbox value="AtmRobbery" :label="dictionary['AtmRobbery']" />
										<n-checkbox value="FleccaHeist" :label="dictionary['FleccaHeist']" />
										<n-checkbox value="ContainerHeist" :label="dictionary['ContainerHeist']" />
										<n-checkbox value="CollectZoneMoney" :label="dictionary['CollectZoneMoney']" />

										
										<n-divider title-placement="left" style="margin-top: 5px; margin-bottom: 0;">
											Admin
										</n-divider>
										<n-checkbox value="Spectate" :label="dictionary['Spectate']" />
										<n-checkbox value="TpTo" :label="dictionary['TpTo']" />
										<n-checkbox value="TpToMe" :label="dictionary['TpToMe']" />
										<n-checkbox value="AdminGiveItem" :label="dictionary['AdminGiveItem']" />
										<n-checkbox value="AdminUnjail" :label="dictionary['AdminUnjail']" />
										<n-checkbox value="AdminActions" :label="dictionary['AdminActions']" />
										<n-checkbox value="CharacterKill" :label="dictionary['CharacterKill']" />
										
										<n-divider title-placement="left" style="margin-top: 5px; margin-bottom: 0;">
											Sindicat
										</n-divider>
										<n-checkbox value="sindicatTax" :label="dictionary['sindicatTax']" />
										
										<n-divider title-placement="left" style="margin-top: 5px; margin-bottom: 0;">
											Politie
										</n-divider>
										<n-checkbox value="PoliceJail" :label="dictionary['PoliceJail']" />
										<n-checkbox value="PoliceFine" :label="dictionary['PoliceFine']" />
										<n-checkbox value="SuspendLicense" :label="dictionary['SuspendLicense']" />

										<n-divider title-placement="left" style="margin-top: 5px; margin-bottom: 0;">
											A.N.A.F.
										</n-divider>
										<n-checkbox value="anafFine" :label="dictionary['anafFine']" />
										<n-checkbox value="BusinessAnafClear" :label="dictionary['BusinessAnafClear']" />

										<n-divider title-placement="left" style="margin-top: 5px; margin-bottom: 0;">
											Business
										</n-divider>
										<n-checkbox value="BusinessSell" :label="dictionary['BusinessSell']" />
										<n-checkbox value="BusinessPaymentOut" :label="dictionary['BusinessPaymentOut']" />
										<n-checkbox value="BusinessPaymentIn" :label="dictionary['BusinessPaymentIn']" />

										<n-divider title-placement="left" style="margin-top: 5px; margin-bottom: 0;">
											Housing
										</n-divider>
										<n-checkbox value="SellHouse" :label="dictionary['SellHouse']" />
										<n-checkbox value="BuyRent" :label="dictionary['BuyRent']" />

										<n-divider title-placement="left" style="margin-top: 5px; margin-bottom: 0;">
											Auction
										</n-divider>
										<n-checkbox value="WinAuction" :label="dictionary['WinAuction']" />

										<n-divider title-placement="left" style="margin-top: 5px; margin-bottom: 0;">
											Altele
										</n-divider>
										<n-checkbox value="RoAlert" :label="dictionary['RoAlert']" />
										<n-checkbox value="weedHarvest" :label="dictionary['weedHarvest']" />
										<n-checkbox value="drugDryer" :label="dictionary['drugDryer']" />
										<n-checkbox value="sortWeed" :label="dictionary['sortWeed']" />
										<n-checkbox value="itemDelivery" :label="dictionary['itemDelivery']" />
										<n-checkbox value="MinerBreakRock" :label="dictionary['MinerBreakRock']" />
										<n-checkbox value="MinerSellRock" :label="dictionary['MinerSellRock']" />
										<n-checkbox value="FisherLogs" :label="dictionary['FisherLogs']" />
										<n-checkbox value="EnterVehicle" :label="dictionary['EnterVehicle']" />
										<n-checkbox value="LeaveVehicle" :label="dictionary['LeaveVehicle']" />
										<n-checkbox value="EnterRadio" :label="dictionary['EnterRadio']" />
										<n-checkbox value="LeaveRadio" :label="dictionary['LeaveRadio']" />

									</n-space>
								</n-checkbox-group>

							</n-space>
						</n-scrollbar>
					</n-gi>
					<n-gi>
						<n-divider vertical style="margin-top: 3.5vh; height: calc(100% - 4vh); float: right;" />
					</n-gi>
				</n-grid>
			</n-gi>
			<n-gi :span="showSpecs ? 'xs:5 m:4 l:4 xl:4 2xl:4' : 'xs:10 m:7 l:7 xl:5 2xl:5'" style="margin-left: 1vw; position: relative;">
				<div class="hide-btn" :style="'left:' + (showSpecs ? '-2.15vw;' : '1vw')">
					<n-button size="small" strong secondary circle @click="showSpecs = !showSpecs">
						<template #icon>
							<i :class="'fa-thin fa-angle-' + (showSpecs ? 'left' : 'right')"></i>
						</template>
					</n-button>
				</div>
				<n-data-table
					:loading="loading"
					:columns="tableColumns"
					:data="tableData"
					:bordered="false"
					:pagination="paginationReactive"
					max-height="65vh"
				/>

			</n-gi>
		</n-grid>
	</n-card>

</template>

<script>

import PostService from '../PostService'
import { useMessage } from 'naive-ui'

const dictionary = {
	"GiveMoney": "Oferă bani",
	"BankTransfer": "Transfer bancar",
	"FireCoinsPayment": "Plată FireCoins",
	"FireCoinsRecive": "Recive FireCoins",
	"BuyVeh": "Cumpărare mașină (Dealership)",
	"SellVeh": "Vânzare mașină",
	"RamVeh": "Vânzare mașină (Amanet Auto)",
	"Login": "Conectări",
	"Logout": "Deconectări",
	"Death": "Kill Log",
	"DamageDealt": "Damage Dealt",
	"InventoryDrop": "Drop (jos)",
	"InventoryPickUp": "Pickup (jos)",
	"InventoryFromStash": "Scoate din Chest",
	"InventoryToStash": "Pune în Chest",
	"InventoryToTarget": "Iteme Oferite",
	"Spectate": "Spectate",
	"TpTo": "TpTo",
	"TpToMe": "TpToMe",
	"AdminGiveItem": "Oferă Item",
	"AdminUnjail": "Admin Unjail",
	"RoAlert": "RoAlert",
	"weedHarvest": "Plante Colectate (weed/tutun)",
	"drugDryer": "Uscare Droguri",
	"sortWeed": "Prelucrare (weed/tutun)",
	"itemDelivery": "Livrare Ilegala",
	"PoliceJail": "Pușcărie oferită",
	"PoliceFine": "Amendă oferită",
	"SuspendLicense": "Confiscare Permis",
	"anafFine": "Amendă oferită",
	"BusinessAnafClear": "Reglementare Business",
	"sindicatTax": "Taxa Sindicat",
	"EnterVehicle": "Intra in Vehicul",
	"LeaveVehicle": "Iese din Vehicul",
	"EnterRadio": "Intra pe statie",
	"LeaveRadio": "Iese de pe statie",
	"RecivedCPR": "Revive Primit (Medkit)",
	"Died": "Respawn (PK)",
	"winBarbut": "Castig Barbut (Target = Loser)",
	"investments": "Start Investment",
	"achiv": "Colectare Achivement",
	"confiscari": "Confiscari",
	"AdminActions": "Admin Actions",
	"MinerBreakRock": "Pietre Sparte (Miner)",
	"MinerSellRock": "Pietre Vandute (Miner)",
	"CraftItem": "Iteme Craftate",
	"VangelicoHeist": "Vangelico Heist",
	"PacificHeist": "Pacific Heist",
	"AtmRobbery": "ATM Robbery",
	"FleccaHeist": "Flecca Heist",
	"ContainerHeist": "Container Heist",
	"CollectZoneMoney": "Colectare Bani Zone",
	"CharacterKill": "Character Kill",
	"BusinessSell": "Sell",
	"BusinessPaymentIn": "Recive Money",
	"BusinessPaymentOut": "Pay Money",
	"SellHouse": "Sell",
	"BuyRent": "Cumpara Chirie",
	"WinAuction": "Win",
	"FisherLogs": "Log-uri Pescar"
}

const logsParams = {
	"Login": ["ip", "fireCoins"],
	"Logout": ["ip", "fireCoins"],
	"Specate": [],
	"Death": [],
	"DamageDealt": [],
	"TpTo": [],
	"TpToMe": [],
	"RoAlert": ["message"],
	"AdminGiveItem": ["amount", "item"],
	"AdminUnjail": [],
	"sortWeed": ["drugType"],
	"SellVeh": ["amount", "vehicle", "plate", "uniqueId"],
	"RamVeh": ["amount", "vehicle", "plate", "uniqueId"],
	"BuyVeh": ["vehicle", "amount", "plate", "uniqueId"],
	"FireCoinsPayment": ["amount", "from"],
	"FireCoinsRecive": ["amount", "from"],
	"GiveMoney": ["amount"],
	"BankTransfer": ["amount"],
	"itemDelivery": ["item"],
	"InventoryDrop": ["item", "amount"],
	"InventoryPickUp": ["item", "amount"],
	"InventoryFromStash": ["stash", "item", "amount"],
	"InventoryToStash": ["stash", "item", "amount"],
	"InventoryToTarget": ["item", "amount"],
	"weedHarvest": ["amount", "drugType"],
	"drugDryer": ["action", "item"],
	"PoliceJail": ["amount"],
	"PoliceFine": ["amount"],
	"SuspendLicense": ["hours"],
	"anafFine": ["amount", "reason"],
	"BusinessAnafClear": ["bizId", "bizType", "amount"],
	"sindicatTax": ["amount", "reason"],
	"EnterVehicle": ["vehicle"],
	"LeaveVehicle": ["vehicle"],
	"EnterRadio": ["freq"],
	"LeaveRadio": ["freq"],
	"RecivedCPR": [],
	"Died": [],
	"winBarbut": ["amount"],
	"investments": ["action", "entry"],
	"achiv": ["achiv", "level", "amount"],
	"confiscari": ["action", "amount", "entry"],
	"AdminActions": ["action"],
	"MinerBreakRock": ["item", "amount"],
	"MinerSellRock": ["item", "amount"],
	"CraftItem": ["item", "amount"],
	"VangelicoHeist": ["amount"],
	"PacificHeist": ["item", "amount"],
	"AtmRobbery": ["amount", "atm"],
	"FleccaHeist": ["amount"],
	"ContainerHeist": ["amount"],
	"CollectZoneMoney": ["location", "amount"],
	"CharacterKill": [],
	"BusinessSell": ["bizId", "bizType", "amount"],
	"BusinessPaymentIn": ["amount", "from"],
	"BusinessPaymentOut": ["amount", "from"],
	"SellHouse": ["houseId", "amount"],
	"BuyRent": ["houseId", "amount", "days"],
	"WinAuction": ["objectId", "type"],
	"FisherLogs": ["action"]
}

export default {
	name: 'LogsTable',
	data() {
		const message = useMessage();
		const nowDate = Date.now();
		return {
			message,
			paginationReactive: {
				page: 1,
				pageSize: 50,
				showSizePicker: true,
				pageSizes: [50, 200, 1000, 10000],
				onChange: (page) => {
					this.paginationReactive.page = page
				},
				onUpdatePageSize: (pageSize) => {
					this.paginationReactive.pageSize = pageSize
					this.paginationReactive.page = 1
				}
			},
			showSpecs: true,
			dictionary,
			params: [],
			paramsData: {},
			types: [],
			dateRange: [nowDate - 2592000000, nowDate], // 30 Days
			userId: null,
			loading: false,

			tableColumns: [],
			tableData: []
		}
	},
	watch: {
		types: function(val) {
			let allParams = [];
			for(let i = 0; i <= val.length; i++) {
				allParams.push.apply(allParams, logsParams[val[i]]);
			}
			this.params = [...new Set(allParams)];
		}
	},
	methods: {
		async search() {
			if(this.loading === true) return;

			let id = parseInt(this.userId);
			if (!id) {
				id = "any";
			}

			if (this.types.length === 0) {
				this.message.error("Selectează minim un tip de log");
				return
			}

			for (const [key, value] of Object.entries(this.paramsData)) {
				if(!value || value.length === 0) {
					delete this.paramsData[key];
					continue;
				}
			}

			// if(id === "any" && Object.entries(this.paramsData).length === 0) {
			// 	this.message.error("Selecteaza minim un câmp");
			// 	return
			// }

			this.loading = true;

			const logs = await PostService.fetchLogs(id, this.dateRange, this.types, this.paramsData);

			let columnsKeys = {
				'target': true
			};
			let data = [];
			let columns = [
				{
					key: "type",
					title: "Tip"
				},
				{
					key: "userId",
					title: "Player"
				},
				{
					key: "target",
					title: "Target"
				}
			];

			let rowTotals = {
				anyTotal: false,
				amount: 0,
				wallet: 0,
				bank: 0,
				fireCoins: 0,
				dmg: 0
			};

			let anyTarget = false;

			for(let i = 0; i < logs.length; i++) {

				let row = {
					key: i,
					userId: logs[i].userId,
					type: this.dictionary[logs[i].type],
					date: logs[i].date
				}

				for (const [key, value] of Object.entries(logs[i].data)) {
					if(!columnsKeys[key]) {
						columnsKeys[key] = true;
						columns.push({key: key, title: key});
					}

					if(!anyTarget && key === "target") {
						anyTarget = true;
					}

					if (rowTotals[key] !== undefined) {
						rowTotals[key] += parseInt(value);
						rowTotals.anyTotal = true;
					}

					if (key == "amount" || key == "wallet" || key == "bank") {
						row[key] = new Intl.NumberFormat().format(parseInt(value))
					} else {
						row[key] = value;
					}
				}

				data.push(row);
			}

			if(rowTotals.anyTotal) {
				let row = {
					key: -1,
					type: "Total",
				}

				for (const [key, value] of Object.entries(rowTotals)) {
					if (value === 0) continue;
					row[key] = new Intl.NumberFormat().format(parseInt(value));
				}
				
				data.unshift(row);
			}

			if(anyTarget === false) {
				// index, numberOfElementsRemoved 
				columns.splice(2, 1); 
			}

			columns.push({
				key: "date",
				title: "Dată",
				sorter: (row1, row2) => row2.key - row1.key
			})

			this.tableColumns = columns;
			this.tableData = data;

			this.loading = false;
		},
		reset() {
			this.types = [];
			this.tableData = [];
			this.paramsData = [];
		}
	}
}
</script>


<style scoped>

.hide-btn {
	position: absolute;
	z-index: 1;
}

</style>